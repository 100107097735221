
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Kolay Yönetim Paneli',
        desc: 'Otelciler, kendi web sitelerini yönetmek için kullanı cı dostu bir yönetim paneline erişebilirler. Bu panel, otelcilerin web sitesinin her yönünü kolayca kontrol etmelerini sağlar. Renkler, fontlar, görseller, başlık lar ve diğer içerikler gibi birçok öğeyi kolayca değiştirebilirler.'
    },
    {
        id: 2,
        img: img2,
        title: 'Çoklu Dil Desteği',
        desc: 'Web sitesi, birden fazla dilde hizmet veren oteller için idealdir. Otelciler, web sitelerinde sunulan içeriği kolayca farklı dillerde yönetebilirler. Bu sayede uluslararası müşterilere daha iyi hizmet sunabilir ve potansiyel müşteri tabanını genişletebi lirler.'
    },
    {
        id: 3,
        img: img3,
        title: 'Özelleştirilebilir Tasarım',
        desc: 'Otelciler, web sitelerinin tasarımını tamamen kendi zevklerine göre özelleştirebilirler. Renk şemalarını, tema seçeneklerini ve görsel elementleri kolayca değiştirebilirler. Bu sayede otelin marka kimliğine uygun bir web sitesi oluşturabilirler.'
    },
    {
        id: 4,
        img: img4,
        title: 'Hızlı Fotoğraf ve İçerik Güncelleme',
        desc: 'Otelciler, web sitelerindeki fotoğrafları, başlıkları ve içerikleri hızlıca güncelleyebilirler. Yeni kampan yalar, etkinlikler veya özel teklifler hakkında bilgi eklemek veya güncellemek istediklerinde, bunu kolayca yapabilirler.'
    },

]

export default dataBox;