
import React, { useEffect } from 'react'
import Header from './components/header/Header';
import AOS from 'aos';
import Home01 from "./pages/Home01";

function App() {

    useEffect(() => {
        AOS.init({
            duration : 2000,
            once: true,
            easing: 'ease-in-out',
        });
      }, []);

    return (
        <>
            <Header />
            <Home01/>
        </>
    );
}

export default App;
