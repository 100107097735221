import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/banner.png'


function Banner(props) {
    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 className="heading">Detaylı Analiz <br />
                                        Sayesinde Rakiplerinizin Önüne Geçin</h2>
                                    <p className="desc">Sizler için hazırlamış olduğumuz fiyat ve yorum analiz programımıza göz atın.</p>
                                    <Link to="mailto:info@travelpratic.com" className="action-btn"><span>Bize Ulaşın</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image">
                                    <img src={img1} alt="cyfonii" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;