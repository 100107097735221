const menus = [
    {
        id: 1,
        name: 'Fiyat Analiz',
        element: 'price_analytic',
    },
    {
        id: 2,
        name: 'Yorum Analiz',
        element: 'comment_analytic',
    },
    {
        id: 3,
        name: 'Rezervasyon Sistemi',
        element: 'reservation_system',
    },
    {
        id: 4,
        name: 'Yönetilebilir Web Sitesi',
        element: 'manageable_website',
    },
    {
        id: 5,
        name: 'Tahsilat',
        element: 'payment',
    }
]

export default menus;