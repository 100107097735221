
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPriceAnalytic = [

    {
        id: 1,
        img: img1,
        title: 'Hızlı Veri Analizi',
        desc: 'Program, binlerce otel ve oda tipi verisini hızla tarar ve karşılaştırır. Bu, otelcilerin rakiplerinin fiyatlandırma stratejilerini anlamalarını sağlar.'
    },
    {
        id: 2,
        img: img2,
        title: 'Düzenli Raporlar',
        desc: 'Oteller, düzenli olarak güncellenen raporlar alarak pazardaki değişiklikleri takip edebilirler. Bu raporlar, fiyat değişikliklerini ve trendleri net bir şekilde gösterir.'
    },
    {
        id: 3,
        img: img3,
        title: 'Anlık Analiz',
        desc: 'Oteller, istedikleri zaman anlık olarak rakip fiyatlarını kontrol edebilirler. Bu sayede hızlı kararlar alabilir ve rekabet avantajlarını koruyabilirler.'
    },
    {
        id: 4,
        img: img4,
        title: 'Özelleştirilebilir Analiz Seçenekleri',
        desc: 'Program, otellerin ihtiyaçlarına göre özelleştirilebilir. Örneğin, belirli bir bölgedeki veya belirli bir tarih aralığındaki fiyatları analiz etmek gibi.'
    },


]

export default dataPriceAnalytic;