import React from 'react';
import dataBox from '../assets/fake-data/data-box';
import dataPriceAnalytic from '../assets/fake-data/data-price-analytic';
import dataCommentAnalytic from '../assets/fake-data/data-comment-analytic';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import PriceAnalytic from "../components/portfolio/PriceAnalytic";
import CommentAnalytic from "../components/portfolio/CommentAnalytic";
function Home01() {
    return (
        <div>
            <Banner />
            <PriceAnalytic data={dataPriceAnalytic} />
            <CommentAnalytic data={dataCommentAnalytic} />
            <Roadmap  data={dataRoadMap} />
            <Speciality data={dataBox} />
            <About />
            <Footer />
        </div>
    );
}

export default Home01;