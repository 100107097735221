

const dataRoadMap = [

    {
        id: 1,
        time: 'February 01, 2022',
        title: 'Esnek Para Birimi Desteği',
        desc: 'Müşterilerinizin dünyanın her yerinden gelme- sine ve rezervasyon yapmasına olanak tanıyan esnek para birimi desteği sayesinde, otelciler herhangi bir sorun yaşamadan farklı para birimlerinde rezervasyon alabilirler. Otel web sitesine entegre edilen widget sistemi, farklı para birimlerinde rezervasyon yapmak isteyen müşterilere uygun seçenekler sunar ve bu işlemi kolaylaştırır.',
        class: ''
    },
    {
        id: 2,
        time: 'February 01, 2022',
        title: 'Canlı Yardım Hizmeti',
        desc: 'Oteliniz, müşterilerinizin herhangi bir sorusu veya ihtiyacı olduğunda anında destek alabile- cekleri canlı yardım hizmeti sunar. Web sitesi- ne entegre edilen canlı yardım penceresi sayesinde, müşteriler doğrudan bir temsilciyle iletişime geçebilir, rezervasyon süreci veya oda tercihleriyle ilgili sorularını sorabilir ve hızlıca çözüme kavuşturabilirler.',
        class: 'right'
    },

    {
        id: 3,
        time: 'February 01, 2022',
        title: 'Telefonla İletişim İmkanı',
        desc: 'Oteliniz, müşterilerinizin telefonla iletişime geçmelerini sağlamak için web sitesine kolay- ca erişilebilir bir telefon numarası ekler. Bu sayede, müşterilerinizin herhangi bir sorusu veya özel isteği olduğunda telefonla doğrudan iletişime geçebilirler. Müşteri memnuniyetini artırmak ve güvenilirliklerini pekiştirmek için iletişim bilgilerinizin web sitenizde net bir şekilde görünür olması çok önemlidir.',
        class: ''
    },

    {
        id: 4,
        time: 'February 01, 2022',
        title: 'Kullanıcı Dostu Widget Sistemi',
        desc: 'Otelciler, rezervasyon sürecini kolaylaştırmak ve yönetmek için kullanıcı dostu bir widget sistemi kullanabilirler. Bu sistemi web siteleri- ne entegre etmek için gereken kodlar sadece 8-9 satırdan oluşur ve otelcilerin müşterilerine farklı para birimlerinde rezervasyon yapma imkanı sunar.',
        class: 'right'
    }



]

export default dataRoadMap;