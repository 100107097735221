
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataCommentAnalytic = [

    {
        id: 1,
        img: img1,
        title: 'Yorum Toplama ve Analiz',
        desc: 'Programımız, otellerin son bir ayda ve son üç ayda aldığı yorumları kolayca toplar ve analiz eder. Hangi otelin hizmetinin iyileştiğini veya kötüleştiğini anlamak için bu verileri detaylı bir şekilde sunar.'
    },
    {
        id: 2,
        img: img2,
        title: 'Misafir Memnuniyeti Değerlendirmes,',
        desc: 'Otelciler, misafirlerin yorumlarını temel alarak misafir memnu niyetini değerlendirebilirler. Hangi konularda olumlu geri bildi rimler alındığını ve hangi konularda iyileştirmeye ihtiyaç duyul duğunu görmek için bu ekranları kullanabilirler.'
    },
    {
        id: 3,
        img: img3,
        title: 'Trend Analizi ve Grafikler',
        desc: 'Programımız, otellerin performansında zaman içindeki trendle ri ve değişiklikleri gösteren grafikler sunar. Bu grafikler, otelci lerin hangi alanlarda daha fazla odaklanmaları gerektiğini belirlemelerine yardımcı olabilir.'
    },
    {
        id: 4,
        img: img4,
        title: 'Rakip Karşılaştırması',
        desc: 'Otelciler, kendi otellerinin performansını rakipleriyle...'
    },


]

export default dataCommentAnalytic;