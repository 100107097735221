import React , {useState} from 'react';

import img1 from '../../assets/images/payment.png'

function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        }
    ])

    const [dataBlock] = useState(
        {
            subheading: 'Otel Tahsilat Sistemi',
            heading: 'Misafirlerinizden Kolayca Ödeme Alın',
            desc1: 'Cyfonii is the premier marketplace for nifties, which are digital items you can truly own for yourself' ,
            desc2 :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat'
        }
    )
    return (
        <section className="about" id="payment">
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <p className="mb-17">
                                        1. Ödeme Linki Oluşturma ve Gönderme:
                                        Otelciler, misafirlerinden kolayca ödeme almak için sistemimizi kullana- rak ödeme linki oluşturabilir ve misafirlerine gönderebilirler. Örneğin, bir
                                        misafir telefonla arayıp oda rezervasyonu yapmak istediğinde, otelci
                                        hızlıca sistemden bir ödeme linki oluşturabilir ve misafire gönderebilir.
                                        Misafir, bu linki kullanarak 3D Secure seçeneğiyle güvenli bir şekilde
                                        ödeme yapabilir.
                                    </p>
                                    <p className="mb-17">
                                        2. Voucher Gönderme:
                                        Otelciler, misafirlere rezervasyon onayı veya özel promosyonlar için
                                        sistem üzerinden voucher gönderebilirler. Bu voucherler, misafirlerin
                                        otele gelmeden önce kullanabilecekleri özel fırsatlar veya avantajlar
                                        içerebilir. Örneğin, bir misafirin doğum günü için özel bir indirim veya
                                        ücretsiz hizmet sunulabilir.
                                    </p>
                                    <p className="mb-17">
                                        3. Güvenli Ödeme ve Veri Koruma:
                                        Tahsilat işlemleri, güvenlik açısından en üst düzeyde korunur. Sistem, 3D
                                        Secure ödeme seçeneğiyle misafirlerin kart bilgilerini korur ve güvenli
                                        bir şekilde işlem yapmalarını sağlar. Ayrıca, misafirlerin kişisel verileri ve
                                        ödeme bilgileri, sıkı gizlilik politikaları ve güvenlik önlemleriyle korunur.
                                    </p>
                                    <p className="mb-17">
                                        Otel tahsilat sistemi, otelcilerin işlerini kolaylaştırırken misafirlerine
                                        güvenli ve pratik bir ödeme deneyimi sunmayı amaçlar. Ödeme linkleri ve
                                        voucherler aracılığıyla, misafirlerin rezervasyon süreci daha hızlı ve
                                        sorunsuz hale gelirken otelcilerin gelirlerini artırma ve müşteri memnuniyetini artırma şansı da yükselir.
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;